import * as React from 'react'
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import { AnchorLink } from 'gatsby-plugin-anchor-links'

import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Button from 'react-bootstrap/Button'

import Layout from '@components/layout'
import MustRead from '@components/must-read'

const SectorsPage = () => {
  return (
    <Layout pageTitle="Sectors">
      <Container fluid="xxl">
        <Row>
          <Col className="no-gutters">
            <div className="hero-banner large-hero">
              <StaticImage
                className="hero-image"
                src="../../media/sectors_hero.jpg"
                alt=""
                aspectRatio={3.75 / 1}
                objectPosition="center top"
                layout="fullWidth" 
              />
              <div className="hero-fade"></div>
              <div className="hero-text">
                <h2>SECTORS</h2>
                <h1>Sectors Overview</h1>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
      <main>
        <a id="food-and-beverage" /> 
        <Container fluid="xxl" className="section default-text">
          <Row xl={2} lg={2} md={2} sm={1} xs={1}>
            <Col> 
              <h3>FOOD AND BEVERAGE</h3>
              <p>
                Waste has become a growing concern in the food and beverage industry, and Wessuc has become a trusted partner of companies that want to reduce costs, ensure compliance and reduce their environmental footprint. Our process management strategies and wastewater treatment technologies can assist wineries, craft breweries, candy manufacturers, dairy product manufacturers, soft drink companies and more with solutions that keep organics out of landfill, maximize water reuse, reduce haulage fees and optimize on-site treatment.
              </p>
            </Col>
            <Col>
              <StaticImage 
                src="../../media/food_and_beverage.jpg"
                alt="an overview of a factory with pipes and conveyor belts" />
            </Col>
            <Col>
                <Button href='/sectors/food-and-beverage' variant='primary' className="shadow">LEARN HOW WE HELP FOOD PROCESSORS</Button>
            </Col>
          </Row>
        </Container>
        <Container><hr/></Container>
        <a id="pulp-and-paper" />
        <Container fluid="xxl" className="section default-text">
          <Row xl={2} lg={2} md={2} sm={1} xs={1}>
            <Col>
                <h3>PULP AND PAPER</h3>
              <p>
                Let the Wessuc team turn your contaminated pulp and paper effluent into a valuable resource. Our wastewater management services for pulp and paper include maintaining your existing wastewater storage facilities, optimizing treatment to reduce haulage costs and maximizing opportunities for in-mill water reuse. A complimentary "quick 'n' dirty" wastewater audit is an excellent way to find out how you can improve your processes. 
              </p>
            </Col>
            <Col>
              <StaticImage 
                src="../../media/pulp_and_paper.jpg"
                alt="a black and white paper loom" />
            </Col>
            <Col>
                <Button href='/misc/free-wastewater-audit' variant='primary' className="shadow">LEARN ABOUT OUR WASTEWATER AUDIT</Button>
            </Col>
          </Row>
        </Container>
        <Container><hr/></Container>
        <a id="municipal" />
        <Container fluid="xxl" className="section default-text">
          <Row xl={2} lg={2} md={2} sm={1} xs={1}>
            <Col>
              <h3>MUNICIPAL</h3>
              <p>
                Municipalities of all sizes across Ontario rely on Wessuc's specialized and certified inspection, dewatering, clean-out and haulage teams to inspect, maintain and repair the critical infrastructure that delivers water and carries away waste, as well as the plants that accept and process that waste. We currently manage more than 400,000 m3 of municipal waste each year.
              </p>
            </Col>
            <Col>
              <StaticImage 
                src="../../media/municipal.jpg"
                alt="a walkway between two wastewater tanks with blue pipes running down the edges" />
            </Col>
          </Row>
        </Container>
        <Container><hr/></Container>
        <a id="agricultural" title="Agriculture" />
        <Container fluid="xxl" className="section default-text">
          <Row xl={2} lg={2} md={2} sm={1} xs={1}>
            <Col>
              <h3>AGRICULTURAL</h3>
              <p>
                Our land application specialists are committed to improving and protecting soil quality. If you're a farmer who's interested in using biosolids, we'll confirm nutrient value, warrant against harmful components, prepare the OMAFRA plans and take care of the application once approval has been received. If you're a farmer with waste ponds or tanks on site, we'll remove, treat and re-apply safe and nutrient-rich biosolids on your land, following OMAFRA approval. 
              </p>
            </Col>
            <Col>
              <StaticImage 
                src="../../media/agricultural.jpg"
                alt="a sprayer works its way through a field" />
            </Col>
            <Col>
                <Button href='/services/residuals/land-application' variant='primary' className="shadow">LEARN ABOUT LAND APPLICATION</Button>
            </Col>
          </Row>
        </Container>
        <Container><hr/></Container>
        <a id="heavy-industry" />
        <Container fluid="xxl" className="section default-text">
          <Row xl={2} lg={2} md={2} sm={1} xs={1}>
            <Col>
              <h3>HEAVY INDUSTRY</h3>
              <p>
                Our clients in steel and other heavy industries count on Wessuc for expert facility maintenance. We'll inspect, clean out and repair in places no one wants to go, including scum pits, incinerators and lagoons, all to keep your plant running 
          efficiently and your production line running without 
              interruption.
            </p>
            </Col>
            <Col>
              <StaticImage 
                src="../../media/heavy_industry.jpg"
                alt="water from a pond flows out of two sluices forming a waterfall" />
            </Col>
            <Col>
                <Button href='/services/clean-out' variant='primary' className="shadow">LEARN ABOUT CLEAN-OUT SERVICES</Button>
            </Col>
          </Row>
        </Container>
        <Container><hr/></Container>
        <a id="construction" />
        <Container fluid="xxl" className="section default-text">
          <Row xl={2} lg={2} md={2} sm={1} xs={1}>
            <Col>
              <h3>CONSTRUCTION</h3>
              <p>
                Our PACP-certified inspection team uses the latest technology to take a close look at underground assets both before and after you've connected your development project to municipal infrastructure to officially confirm there's been no damage during the construction process. If damage has occurred, our professional operators can perform the necessary repairs, sometimes the same day.
              </p>
            </Col>
            <Col>
              <StaticImage 
                src="../../media/construction.jpg"
                alt="an unearthed metallic pipe lays in redish dirt" />
            </Col>
            <Col>
                <Button href='/services/inspection' variant='primary' className="shadow">LEARN ABOUT INSPECTIONS</Button>
            </Col>
          </Row>
        </Container>
      </main>
      <MustRead />
    </Layout>
  )
}

export default SectorsPage